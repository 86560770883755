import ContentLoader from 'react-content-loader'

const TalkListPreview = (props) => {
  return (
    <div className="talk_list_preview">
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
      <ContentLoader backgroundColor="#547598" foregroundColor="#547598" />
    </div>
  )
}

export default TalkListPreview
