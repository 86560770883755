import React, { useContext, useState, useRef } from 'react'
import { Form, Formik, Field } from 'formik'
import classNames from 'classnames'
import * as Yup from 'yup'

import { FormInputWithLabel } from 'components/Form/'
import Popup from '../Popup/Popup'
import { UiContext } from 'context/ui'
import { useClickOutside } from 'hooks'

import 'react-nice-dates/build/style.css'
import styles from './SummaryPopup.module.scss'

import { ReactComponent as EmailIcon } from 'i/icons/email2.svg'
import { ReactComponent as PlusIcon } from 'i/icons/plus.svg'
import { ReactComponent as MinusIcon } from 'i/icons/minus.svg'
import { PROMISE_STATES } from 'utils/constants'
import { callApi } from 'api'
import { Button } from 'components/ui/Button'

const SummaryPopup = ({ isOpen, callId }) => {
  const [inputs, setInputs] = useState([{ id: 1 }])
  const [sendingMessageStatus, setSendingMessageStatus] = useState(PROMISE_STATES.idle)

  const addItem = () => {
    setInputs([...inputs, { id: Date.now() }])
  }

  const removeItem = (id) => {
    setInputs(inputs.filter((item) => item.id !== id))
  }

  const { setActivePopupId } = useContext(UiContext)

  const $container = useRef()

  const handleClose = () => {
    setActivePopupId(null)
  }

  useClickOutside($container, handleClose)

  const rateSchema = Yup.object().shape({
    summary_emails: Yup.array().of(
      Yup.string().email('Invalid email').required('Email is required')
    ),
  })

  const toggleInput = (index, id) => {
    if (index === 0) {
      addItem()
    } else {
      removeItem(id)
    }
  }

  const handleSubmit = async (values, { setSubmitting, resetForm, setTouched }) => {
    const results = { invited_users: Object.values(values) }
    setSendingMessageStatus(PROMISE_STATES.pending)

    try {
      const res = await callApi.shareSummary(results, callId)

      if (res.error) {
        setSendingMessageStatus(PROMISE_STATES.rejected)
        setTimeout(() => {
          setSendingMessageStatus(PROMISE_STATES.idle)
        }, 1500)
      } else {
        setSendingMessageStatus(PROMISE_STATES.fulfilled)

        setTimeout(() => {
          handleClose()
        }, 500)

        setTimeout(() => {
          resetForm()
          setSendingMessageStatus(PROMISE_STATES.idle)
        }, 1500)
      }
    } catch (error) {
      setSendingMessageStatus(PROMISE_STATES.rejected)
      setTimeout(() => {
        setSendingMessageStatus(PROMISE_STATES.idle)
      }, 1500)
    }
  }

  return (
    <Popup
      isOpen={isOpen}
      title="Share Meeting Summary"
      description="Enter the email address of your attendees or colleagues you would like to share the AI powered summary from your talk"
      centerContent
    >
      <Formik
        initialValues={{
          summary_emails: Array(1).fill(''),
        }}
        validationSchema={rateSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue, submitForm, errors, touched }) => {
          return (
            <Form className={styles.form}>
              {inputs.map((item, index) => (
                <div className={classNames(styles.item)} key={item.id}>
                  <div
                    className={classNames(
                      styles.block,
                      'form_group form_group--icon_mod'
                    )}
                  >
                    <FormInputWithLabel
                      type="text"
                      label="Email address"
                      icon={<EmailIcon />}
                      iconPosition
                      colorV3
                      sizeV2
                    >
                      <Field
                        className="form_group__field"
                        name={`summary_emails[${index}]`}
                        type="email"
                      />
                    </FormInputWithLabel>
                    <button
                      type="button"
                      className={styles.countBtn}
                      onClick={() => {
                        toggleInput(index, item.id)
                      }}
                    >
                      {index === 0 ? <PlusIcon /> : <MinusIcon />}
                    </button>
                  </div>

                  {errors.summary_emails &&
                  errors.summary_emails[index] &&
                  touched.summary_emails &&
                  touched.summary_emails[index] ? (
                    <div className="form_group__error">
                      {errors.summary_emails[index]}
                    </div>
                  ) : null}
                </div>
              ))}
              <Button
                onClick={async (e) => {
                  e.preventDefault(e)
                  submitForm()
                }}
                sendingMessageStatus={sendingMessageStatus}
                variant="tertiary"
                successVariant="quaternary"
                size="lg"
                fullWidth
              >
                Send Summary
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Popup>
  )
}

export default SummaryPopup
