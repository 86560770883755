import { useEffect, useState } from 'react'
import useFetch from './useFetch' // Импортируйте существующий хук useFetch

const useFetchWithRetry = (fetchFunction, variant, interval = 3000) => {
  const { loading, error, data } = useFetch(fetchFunction)
  const [retryData, setRetryData] = useState(null)
  const [retryError, setRetryError] = useState(null)
  const [retryLoading, setRetryLoading] = useState(false)

  useEffect(() => {
    if (
      loading === 'fulfilled' &&
      (variant === 'join_call_url'
        ? data.result.join_call_url
        : !data?.result?.data || !data.result.data.meetingNotes)
    ) {
      setRetryLoading(true)
      const retryInterval = setInterval(async () => {
        try {
          const retryResult = await fetchFunction()
          console.log('FIVE')
          if (
            variant === 'join_call_url'
              ? !retryResult.result.join_call_url
              : retryResult.result.data.meetingNotes
          ) {
            setRetryData(retryResult)
            setRetryLoading(false)
            clearInterval(retryInterval)
          }
        } catch (err) {
          setRetryError(err)
          setRetryLoading(false)
          clearInterval(retryInterval)
        }
      }, interval)

      return () => clearInterval(retryInterval)
    } else {
      setRetryData(data)
      setRetryLoading(loading === 'pending')
      setRetryError(error)
    }
  }, [loading, data, error, fetchFunction, interval])

  return {
    loading: retryLoading ? 'pending' : loading,
    error: retryError || error,
    data: retryData,
  }
}

export default useFetchWithRetry
