import styles from './LoadingBarBlock.module.scss'
import { ReactComponent as LogoImage } from 'i/logo.svg'

export const LoadingBarBlock = ({ title }) => {
  return (
    <div className={styles.loadingContainer}>
      <div class={styles.loadingLogo}>
        <LogoImage />
      </div>
      <div class={styles.loadaingTitle}>{title}</div>
      <div class={styles.loadaingBar}>
        <div class={styles.loadaingBarIn} />
      </div>
    </div>
  )
}
